import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Stack,
    IconButton,
    MenuItem,
    Typography,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData,
    NumberStatus,
    SetType,
    getSelectOptions,
    getLabelByValue,
    Capability, NumberSearch, sortByOptions
} from 'src/constants';
import { SearchNumberForm, DeallocateNumberForm } from './Forms';
import {CommonService, NumberService, IdentityService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ViewDialog from "./Forms/ViewDialog";
import ScheduleDialog from "./Forms/ScheduleDialog";
import PortoutDialog from "./Forms/PortoutDialog";
import NumberPortoutForm from "./Forms/NumberPortoutForm";
import {useStore} from "../../../store/Store";
import ResubmitDialog from './Forms/ResubmitDialog';
import ResubmitForm from './Forms/ResubmitForm';
import BaseCard from 'src/components/BaseCard';
import BaseTextField from 'src/components/BaseTextField';
import BaseSelect from 'src/components/BaseSelect';
import { useTheme } from '@mui/material/styles';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const StyledCheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    color: theme.palette['--text-secondary'],
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function Number() {
    const { t } = useTranslation();
    
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openSearchNumbersDialog, setSearchNumbersDialogStatus] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [openPortoutDialog, setOpenPortoutDialog] = useState(false);
    const [openResubmitDialog, setOpenResubmitDialog] = useState(false);
    const [openResubmitForm, setOpenResubmitForm] = useState(false);
    const [openPortoutForm, setOpenPortoutForm] = useState(false);
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [identities, setIdentities] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterStatus, setStatus] = useState("");
    const [filterNumberSearch, setFilterNumberSearch] = useState(3);
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterNumber, setNumber] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");
    const [filterAddress, setFilterAddress] = useState("");
    const [filterIdentity, setFilterIdentity] = useState("");
    const [providerCodes, setProviderCodes] = useState([]);
    const [filterProvider, setFilterProvider] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [filterShowBlocks, setFilterShowBlocks] = useState("false");

    const [store, dispatch] = useStore();
    const advancedTranslationEnabled = store.portalSettings.services.access_management.advanced_translation ?? false;
    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const displayPorting = store.portalSettings.services.portal_features.display_porting ?? false;
    const portingFeature = store.portalSettings.services.access_management.porting ?? false;

    const TABLE_HEAD = providersEnabled ? [
        { key: "country", label: t("country") },
        { key: "provider_code", label: t("Provider") },
        { key: "capability", label: "Capability" },
        { key: "type", label: "Type" },
        { key: "identity", label: t('identity') },
        { key: "address", label: t('address') },
        { key: "status", label: t('common.__i18n_ally_root__.status') },
        { key: "did", label: t('number') },
        { key: "action", label: t('actions') },
    ] : [
        { key: "country", label: t("country") },
        { key: "capability", label: "Capability" },
        { key: "type", label: "Type" },
        { key: "identity", label: t('identity') },
        { key: "address", label: t('address') },
        { key: "status", label: t('common.__i18n_ally_root__.status') },
        { key: "did", label: t('number') },
        { key: "action", label: t('actions') },
    ];

    const TABLE_FIELD_MAPPING = providersEnabled ? {
        country: { key: "country", index: 0 },
        provider_code: { key: "provider_code", index: 1 },
        capability: { key: "capability", index: 2 },
        type: { key: "type", index: 3 },
        status: { key: "status", index: 4 },
        identity: { key: "identity", index: 5 },
        address: { key: "address", index: 6 },
        did: { key: "did", index: 7 },
        did_id: { key: "did_id", index: 8, noRender:true },
        number_porting: { key: "number_porting", index: 9, noRender:true },
        country_code: { key: "country_code", index: 10, noRender:true },
        order_id: { key: "order_id", index: 11, noRender:true }
    } : {
        country: { key: "country", index: 0 },
        capability: { key: "capability", index: 1 },
        type: { key: "type", index: 2 },
        identity: { key: "identity", index: 3 },
        address: { key: "address", index: 4 },
        status: { key: "status", index: 5 },
        did: { key: "did", index: 6},
        did_id: { key: "did_id", index: 7, noRender:true },
        number_porting: { key: "number_porting", index: 8, noRender:true },
        country_code: { key: "country_code", index: 9, noRender:true },
        order_id: { key: "order_id", index: 10, noRender:true }
    };

    const fetchNumbers = () => {
        const params = {
            country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,
            provider_code: filterProvider ? filterProvider == "0" ? undefined : filterProvider : undefined,
            capability: filterCapability ? filterCapability : undefined,
            number_type: filterNumberTypes ? filterNumberTypes : undefined,
            status: filterStatus !== "0" ? filterStatus : undefined,
            number_regex: filterNumberSearch ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            address : filterAddress ? filterAddress : undefined,
            identity : filterIdentity ? filterIdentity : undefined,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
            sort_by: sortBy ? sortBy : undefined,
        };
        setLoadingData(true);
        NumberService.listNumbers(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["iso_code_2digit"] });
                })

                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = () => {
        NumberService.getDIDTypes({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchProviderCodes = () => {
        const params = {
            country: filterCountry ? filterCountry : undefined,
            capability: filterCapability ? filterCapability : undefined,
            number_type: filterNumberTypes ? filterNumberTypes : undefined,
        }
        NumberService.getProviderCodes(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodes(items);
            })
            .catch((err) => {
                setProviderCodes([]);
                console.log(err);
            })
    };

    const fetchAddresses = () => {
        IdentityService.listAddresses({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data.items).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"] });
                })
                setAddresses(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchIdentites = () => {
        IdentityService.listIdentities({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data.items).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["identity_name"] });
                })
                setIdentities(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    useEffect(() => {
        fetchCountries();
        fetchNumbers();
        fetchDIDTypes();
        fetchProviderCodes();
        fetchAddresses();
        fetchIdentites();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    useEffect(() => {
        fetchProviderCodes();
    }, [filterCountry, filterNumberTypes, filterCapability]);

    const theme = useTheme();


    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(NumberStatus(), d.value.toString()), chipColor: d.value === 3 ? "--color-success" : "--color-warning",
                });
            } else if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else if (d.key === "type") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(numberTypes, d.value.toString()),
                });
            } else if (d.key === "in_use") {
                formatted.push({
                    ...d,
                    value: d.value ? t('yes') : t('no'),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "searchNumbers") {
            setSearchNumbersDialogStatus(!openSearchNumbersDialog);
        } else if (modalType === "deallocate") {
            setDeallocateModalStatus(!openDeallocateModal);
        } else if (modalType === "infoDialog") {
            setOpenInfoDialog(!openInfoDialog);
        } else if (modalType === "scheduleDialog") {
            setOpenScheduleDialog(!openScheduleDialog);
        }
         else if (modalType === "portoutDialog") {
             setOpenPortoutDialog(!openPortoutDialog);
         }
         else if (modalType === "resubmitDialog") {
            setOpenResubmitDialog(!openResubmitDialog);
        }


        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const getActionItems = (index) => {
            return (
                <Stack direction="row" justifyContent='start' spacing={2}>
                   
                    <>
                     <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <VisibilityIcon
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-success']}}
                            onClick={() => modalHandler("infoDialog", index)}/>
                    </IconButton>
                    <IconButton sx = {{display: "flex", justifyContent: "flex-start"}}>
                        <EditIcon
                            sx = {{
                                width:30,
                                height:30,
                                borderRadius:0.5,
                                color: "white",
                                backgroundColor: theme.palette['--color-success']}}
                            onClick={() => modalHandler("scheduleDialog", index)}/>
                    </IconButton>
                    </>

                    <Grid container direction="row" alignItems="center" justifyItems="center" columnSpacing={1}>

                    {data[index].find((item) => item.key === "status").value == 3 &&
                    <Grid item> <BaseButton color={'--color-warning'} label={'Deallocate'}
                                                                    onClick={() => modalHandler("deallocate", index)}
                    /></Grid>}


                    {portingFeature && displayPorting && (
                        <>
                            { data[index].find((item) => item.key === "status").value == 3  && data[index].find((item) => item.key === "number_porting").value == true && <Grid item> <BaseButton
                                label={'Pending Portout'}
                                onClick={null} color={"--color-search"}
                            /> </Grid>}
    
                            { data[index].find((item) => item.key === "status").value == 3  && data[index].find((item) => item.key === "number_porting").value == false && <Grid item>  <BaseButton
                                label={'Port out'}
                                onClick={() => modalHandler("portoutDialog", index)} color={"--color-danger"}
                            /></Grid>}
                        </>)
                    }
                    </Grid>
                </Stack>
            )
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseDialog title={t('search-numbers')} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openSearchNumbersDialog} setOpen={setSearchNumbersDialogStatus} children={<SearchNumberForm successCallback={fetchNumbers} formType="add" formData={{}} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={t('File Upload')} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openResubmitForm} setOpen={setOpenResubmitForm} children={<ResubmitForm fetchItems ={fetchNumbers}  successCallback={setOpenResubmitForm} formType="add" formData={selectedRow} setModalStatus={setOpenResubmitDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Deallocation Request - ' + selectedRow.did} open={openDeallocateModal} setOpen={setDeallocateModalStatus} children={<DeallocateNumberForm successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setDeallocateModalStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Translation Details - ' + selectedRow.did} open={openInfoDialog} setOpen={setOpenInfoDialog} children={<ViewDialog successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setOpenInfoDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Translation Details - ' + selectedRow.did} open={openScheduleDialog} setOpen={setOpenScheduleDialog} children={<ScheduleDialog successCallback={fetchNumbers} formData={selectedRow} setModalStatus={setOpenScheduleDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Are you sure?'} open={openPortoutDialog} setOpen={setOpenPortoutDialog} children={<PortoutDialog successCallback={() => setOpenPortoutForm(true)} formData={selectedRow} setModalStatus={setOpenPortoutDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={'Are you sure?'} open={openResubmitDialog} setOpen={setOpenResubmitDialog} children={<ResubmitDialog successCallback={() => setOpenResubmitForm(true)} formData={selectedRow} setModalStatus={setOpenPortoutDialog} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={'New Port-Out Request'} dialogProperties={DIALOG_PROPERTIES} closeCallback={() => setOpenPortoutDialog(false)} open={openPortoutForm} setOpen={setOpenPortoutForm} children={<NumberPortoutForm successCallback={fetchNumbers} closeCallback={() => setOpenPortoutDialog(false)} formData={selectedRow} setModalStatus={setOpenPortoutForm} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
           <BaseCard>
           <TableFilterContainer>
                <Grid sx={{ alignItems: "center", justifyItems: "center", alignContent: "center" }} container spacing={2}>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setFilterCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>

                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Capability'}</InputLabel>
                            <BaseSelect
                                label={'capability'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={filterCapability}
                                onChange={event => {setCapability(event.target.value) }}
                            >
                                {getSelectOptions(Capability())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                     <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{'Type'}</InputLabel>
                            <BaseSelect
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterNumberTypes}
                                onChange={event => { setFilterNumberTypes(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-address-label">{'Address'}</InputLabel>
                            <BaseSelect
                                label={'address'}
                                labelId="filter-address-label"
                                name="address"
                                color="secondary"
                                value={filterAddress}
                                onChange={event => { setFilterAddress(event.target.value) }}
                            >
                                {getSelectOptions(addresses)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-identity-label">{'Identity'}</InputLabel>
                            <BaseSelect
                                label={'identity'}
                                labelId="filter-identity-label"
                                name="identity"
                                color="secondary"
                                value={filterIdentity}
                                onChange={event => { setFilterIdentity(event.target.value) }}
                            >
                                {getSelectOptions(identities)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('common.__i18n_ally_root__.status')}</InputLabel>
                            <BaseSelect
                                label={t('common.__i18n_ally_root__.status')}
                                labelId="filter-status-label"
                                name="status"
                                color="secondary"
                                value={filterStatus}
                                onChange={event => { setStatus(event.target.value) }}
                            >
                                {getSelectOptions(NumberStatus())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    
                    <Grid direction="row" item md={12}>
                    
                    </Grid>
                    {providersEnabled && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Provider"}</InputLabel>
                            <BaseSelect
                                label={"Provider"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={filterProvider}
                                onChange={event => { setFilterProvider(event.target.value) }}
                            >
                                {getSelectOptions(providerCodes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}
                        
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Number Search'}</InputLabel>
                            <BaseSelect
                                label={'number-search'}
                                labelId="filter-number-search-label"
                                name="number-search"
                                color="secondary"
                                value={filterNumberSearch}
                                onChange={event => { setFilterNumberSearch(event.target.value) }}
                            >
                                {getSelectOptions(NumberSearch(), ['0'], false)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>

                            <BaseTextField  value={filterNumber}
                                            label={t('number-0')}
                                            name="number"
                                            onChange={setNumber }
                            />

                        </FormControl>
                    </Grid>
                    

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Sort By'}</InputLabel>
                            <BaseSelect
                                label={'Sort By'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={sortBy}
                                onChange={event => {setSortBy(event.target.value) }}
                            >
                                {getSelectOptions(sortByOptions())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                        <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                    </TitleStyle>
                    <br />

                </SearchButtonContainer>
                </TableFilterContainer>
           </BaseCard>
            <br />

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
