import { useState } from 'react';
// material
import {
    Stack,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import BaseButton from 'src/components/buttons/BaseButton';
import {useEffect} from "react";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function ChannelDialog({ formData, setModalStatus }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [productName, setProductName] = useState('');

    useEffect(() => {
        console.log('formData', formData);
        if (formData.product) {
            setProductName(formData.product);
        }

    }, [formData]);

    const handleContinue = () => {
        setModalStatus(false);
        navigate('/channels');
    }

    return (
        <>
            <Stack spacing={1}>
                <Typography color="text.primary">
                    {"Channel order required for pre-order request with product: " + productName}
                </Typography>

                <br/>

                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        label={'Cancel'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('Continue with channel order')}
                        onClick={handleContinue}
                        color={"--color-primary"}
                    />
                </Stack>
            </Stack>
        </>
    );
}
