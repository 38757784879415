import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Stack,
    MenuItem,
    Typography, Box, Button
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData,
    getSelectOptions,
    getLabelByValue,
    Capability, NumberSearch,
    BlockSize
} from 'src/constants/index';
import {CommonService, NumberService, BlockService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import BuyNumberForm from "../number/Forms/BuyNumberForm";
import FileUploadForm from "./Forms/FileUploadForm";
import {useStore} from "../../../store/Store";
import BaseCard from 'src/components/BaseCard';
import BaseSelect from 'src/components/BaseSelect';
import BaseTextField from 'src/components/BaseTextField';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import RequestForm from "../number/Forms/RequestForm";
import NumberPortoutForm from "../number/Forms/NumberPortoutForm";
import NumberRequestForm from "../number/Forms/NumberRequestForm";
import {useNavigate} from "react-router-dom";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function BuyBlocks() {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const navigate = useNavigate();

    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const usFeaturesEnabled = store.portalSettings.services.access_management.us_features ?? false;
    
    const checkDisplay = (field) => {
        console.log(store.portalSettings.services.tariff_fields.inbound[field], field);
        return store.portalSettings.services.tariff_fields.inbound[field];
    }


    const TABLE_HEAD = [
        { key: "start_number", label: "Start Number" },
        { key: "end_number", label: "End Number" },
        { key: "block_size", label: "Block Size" },
        providersEnabled ? { key: "provider_code", label: "Provider" } : null,
        { key: "country_code", label: "Country" },
        { key: "capability", label: "Capability" },
        { key: "did_type", label: "Type" },
        checkDisplay("nrc") ? { key: "nrc", label: t('NRC') } : null,
        checkDisplay("mrc") ? { key: "mrc", label: t('MRC') } : null,
        checkDisplay("sms_rate") ? { key: "sms_price", label: 'SMS Price' } : null,
        checkDisplay("peak_voice_rate") ? { key: "voice_price", label: 'Voice Price' } : null,
        checkDisplay("weekend_peak_voice_rate") ? { key: "voice_weekend_price", label: 'Weekend Price' } : null,
        checkDisplay("mobile_origin_rate") ? { key: "voice_mobile_price", label: 'Mobile Price' } : null,
        checkDisplay("payphone_origin_rate") ? { key: "voice_payphone_price", label: 'Payphone Price' } : null,
        checkDisplay("offpeak_voice_rate") ? { key: "voice_offpeak_price", label: 'Offpeak Price' } : null,
        { key: "actions", label: t('actions') },
    ].filter(Boolean);

    const TABLE_FIELD_MAPPING = TABLE_HEAD.reduce((mapping, field, index) => {
        mapping[field.key] = { key: field.key, index };
        return mapping;
    }, {});
    
    TABLE_FIELD_MAPPING.currency_symbol = { key: "currency_symbol", index: TABLE_HEAD.length+1, noRender: true };
    TABLE_FIELD_MAPPING.sell_product_id = { key: "sell_product_id", index: TABLE_HEAD.length+2, noRender: true };
    TABLE_FIELD_MAPPING.channel_required = { key: "channel_required", index: TABLE_HEAD.length+3, noRender: true };
    TABLE_FIELD_MAPPING.channel_order_exists = { key: "channel_order_exists", index: TABLE_HEAD.length+4, noRender: true };
    TABLE_FIELD_MAPPING.sell_product_name = { key: "sell_product_name", index: TABLE_HEAD.length +5, noRender: true };
    TABLE_FIELD_MAPPING.channel_order_exists = { key: "channel_order_exists", index: TABLE_HEAD.length + 6, noRender: true };
    TABLE_FIELD_MAPPING.channel_required = { key: "channel_required", index: TABLE_HEAD.length + 7, noRender: true };
    TABLE_FIELD_MAPPING.block_id = { key: "block_id", index: TABLE_HEAD.length + 8, noRender: true };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openBuyNumberStatus, setOpenBuyNumberStatus] = useState(false);
    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
 
    const [numberTypes, setNumberTypes] = useState([]);
    const [providerCodes, setProviderCodes] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterProvider, setFilterProvider] = useState("");
    const [filterNumberSearch, setFilterNumberSearch] = useState("");
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterNumber, setFilterNumber] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");

    const [filterCity, setFilterCity] = useState(0);
    const [filterState, setFilterState] = useState(0);

    const [filterNpa, setFilterNpa] = useState("");
    const [filterNxx, setFilterNxx] = useState("");

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [capabilities, setCapabilities] = useState([]);

    const fetchBlocks = () => {
        setLoadingData(true);
        setData([]);
        setOpenFileUpload(false);
        setOpenBuyNumberStatus(false);
        const params = {
            country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,

            capability: filterCapability ? filterCapability == "0" ? undefined : filterCapability : undefined,

            number_type: filterNumberTypes ? filterNumberTypes == "0" ? undefined : filterNumberTypes : undefined,

            provider_code: filterProvider ? filterProvider == "0" ? undefined : filterProvider : undefined,

            number_regex: filterNumberSearch ? filterNumberSearch : undefined,

            number_value: filterNumber ? filterNumber : undefined,

            npa: filterNpa ? filterNpa : undefined,
            nxx: filterNxx ? filterNxx : undefined,
            
            country_state: filterState != 0 ? filterState : undefined,
            country_city: filterCity != 0 ? filterCity : undefined,
            
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage
        };

        BlockService.searchBlocks(params)
            .then((response) => {
                let items = [];

                setTotalCount(response.data.data.count);


                for (const idx in response.data.data.items) {
                    items.push({...response.data.data.items[idx]})
                }

                response.data.data.items = items;
                items = [];

                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    const itemData = response.data.data.items[idx];


                    Object.entries(itemData).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
               
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchCapabilities = () => {
        let params = {
                country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,
                did_type: filterNumberTypes ? filterNumberTypes == "0" ? undefined : filterNumberTypes : undefined,
        }
        
        NumberService.getCapabilities(params)
            .then((response) => {
                let items = [];
                if (response.data.data != undefined) {
                    Object.entries(response.data.data).forEach((item) => {
                        items.push({ value: item[1]["id"], label: item[1]["name"] });
                    })
                }
                console.log(items);
                setCapabilities(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };


    const fetchCities = (country, preOrder) => {
        CommonService.getCities({"country": country})
            .then((response) => {
              
                setCities([]);
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ id: item[1]["id"], value: item[1]["id"], state: item[1]["state"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                })
                setCities(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchStates = (country, preOrder) => {
        CommonService.getStates({"country": country})
            .then((response) => {
                setStates([]);
                
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"], id: item[1]["id"]});
                })
               
                setStates(items);
                console.log(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    function findObjectByValue(array, targetValue) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].value === targetValue) {
                return array[i].is_fixed;
            }
        }
        return null;
    }
    
    useEffect(() => {
        if(findObjectByValue(numberTypes, filterNumberTypes)){
            fetchCities(filterCountry, false);
            fetchStates(filterCountry, false);
        }else{
            setCities([]);
            setStates([]);
            setFilterCity(0);
            setFilterState(0);
        }
    }, [filterNumberTypes, filterCountry]);


    const fetchDIDTypes = (country) => {
        let params = {};
        if (country != undefined) {
            params = {
                country: country ? country == "0" ? undefined : country : undefined,
                capability: filterCapability ? filterCapability == "0" ? undefined : filterCapability : undefined
            }
        }else{
            params = {
                country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,
                capability: filterCapability ? filterCapability == "0" ? undefined : filterCapability : undefined
            }
        }
        NumberService.getDIDTypes(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };




    const fetchProviderCodes = (country, capability, type) => {
        const params = {
            country: country ? country : undefined,
            capability: capability ? capability : undefined,
            number_type: type ? type : undefined
        }
        NumberService.getProviderCodes(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodes(items);
            })
            .catch((err) => {
                setProviderCodes([]);
                console.log(err);
            })
    };



    useEffect(() => {
        fetchProviderCodes();
        fetchCountries();
        fetchBlocks();
        fetchDIDTypes();
    }, [paginationData]);

    useEffect(() => {
        fetchProviderCodes(filterCountry, filterCapability, filterNumberTypes);
    }, [filterCountry, filterCapability, filterNumberTypes]);


    useEffect(() => {
        fetchDIDTypes();
    }, [filterCountry, filterCapability]);


    useEffect(() => {
        fetchCapabilities();
    }, [filterCountry, filterNumberTypes]);

    useEffect(() => {
        setFilterNumberTypes("");
        setCapability("");
    }, [filterCountry]);

    const formatRowData = (rowData) => {
        let formatted = [];

        rowData.map((d, idx) => {
            
            let currency_symbol = rowData[TABLE_FIELD_MAPPING.currency_symbol.index].value;
            console.log(currency_symbol);

             if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else if (d.key === "voice_price" || d.key === "voice_weekend_price" || d.key === "voice_mobile_price" || d.key === "voice_payphone_price" || d.key === "voice_offpeak_price" || d.key === "sms_price" || d.key === "nrc" || d.key === "mrc") {

                formatted.push({
                    ...d,
                    value:  currency_symbol + d.value,
                });
            
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };

        if (modalType === "buy") {
            setOpenBuyNumberStatus(!openBuyNumberStatus);
        }if (modalType === "addToCart") {
            setOpenFileUpload(!openFileUpload);
        }if (modalType === "removeFromCart") {
            
        }else if (modalType === "buyChannel") {
            navigate(`/channels?buy=true&sell_product_id=${data[index][TABLE_FIELD_MAPPING.sell_product_id.index].value}&sell_product_name=${data[index][TABLE_FIELD_MAPPING.sell_product_name.index].value}`);
        }
        else if (modalType === "deallocate") {
            setDeallocateModalStatus(!openDeallocateModal);
        }

    }


    const getActionItems = (index) => {
        return (
                <Stack direction="row" spacing={2}>
                    {data[index][TABLE_FIELD_MAPPING.channel_order_exists.index].value == false && data[index][TABLE_FIELD_MAPPING.channel_required.index].value == true &&
                    (<>
                        <BaseButton
                        children={<AddShoppingCartIcon sx={{marginRight:0.5}}/>}
                        label={'Buy Channel'}
                        onClick={() => modalHandler("buyChannel", index)} color={"--color-success"}
                        />
                    </>)
                    }
                   <BaseButton
                        children={<AddShoppingCartIcon sx={{marginRight:0.5}}/>}
                        label={'Buy Block'}
                        onClick={() => modalHandler("addToCart", index)} color={"--color-success"}
                    />
                </Stack>
            )
    }

    const openFileDialog = () => {
        setOpenBuyNumberStatus(false);
        setOpenFileUpload(true);
    }

    const setCountry = (country) => {
        setFilterCountry(country);
    }



    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />

            <BaseDialog title={"Buy Number"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchBlocks} open={openFileUpload} setOpen={openFileDialog} children={<FileUploadForm successCallback={fetchBlocks} formType="add" formData={selectedRow} setModalStatus={setOpenFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={"Are you sure?"} open={openBuyNumberStatus} setOpen={setOpenBuyNumberStatus} children={<BuyNumberForm successCallback={fetchBlocks} formData={selectedRow} setOpenBuyNumber={setOpenFileUpload} setModalStatus={setOpenBuyNumberStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />

            <BaseDialog title={"Finalize Purchase"} dialogProperties={DIALOG_PROPERTIES} closeCallback={setOpenFileUpload} open={openFileUpload} setOpen={setOpenFileUpload} children={<FileUploadForm successCallback={fetchBlocks} formType="add" formData={selectedRow} setModalStatus={setOpenFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />


            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>


                    {states.length > 0 && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-state-label">{t('State')}</InputLabel>
                            <BaseSelect
                                label={'State'}
                                labelId="filter-state-label"
                                name="State"
                                color="secondary"
                                value = {filterState}
                                onChange={event => { setFilterState(event.target.value) }}
                            >

                                {getSelectOptions(states)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}
                    {cities.length > 0 && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-city-label">{t('City')}</InputLabel>
                            <BaseSelect
                                label={'City'}
                                labelId="filter-city-label"
                                name="City"
                                color="secondary"
                                value={filterCity }
                                onChange={event => { setFilterCity(event.target.value) }}
                            >
                                {getSelectOptions(cities)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{'Type'}</InputLabel>
                            <BaseSelect
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                disabled= {!filterCountry || filterCountry === "0"}
                                value={filterNumberTypes}
                                onChange={event => { setFilterNumberTypes(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Capability'}</InputLabel>
                            <BaseSelect
                                label={'capability'}
                                labelId="filter-capability-label"
                                name="capability"
                                disabled= {!filterCountry || filterCountry === "0"}
                                color="secondary"
                                value={filterCapability}
                                onChange={event => {setCapability(event.target.value) }}
                            >
                                {getSelectOptions(capabilities)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    {providersEnabled && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Provider"}</InputLabel>
                            <BaseSelect
                                label={"Provider"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={filterProvider}
                                onChange={event => { setFilterProvider(event.target.value) }}
                            >
                               
                                {getSelectOptions(providerCodes)}

                            </BaseSelect>
                        </FormControl>
                    </Grid>)}


                    {usFeaturesEnabled && (
                        <>
                        <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNpa}
                                label={t('NPA')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterNpa(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNxx}
                                label={t('NXX')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterNxx(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    </>
                    )}

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Number Search'}</InputLabel>
                            <BaseSelect
                                label={'number-search'}
                                labelId="filter-number-search-label"
                                name="number-search"
                                color="secondary"
                                value={filterNumberSearch}
                                onChange={event => { setFilterNumberSearch(event.target.value) }}
                            >
                                {getSelectOptions(NumberSearch())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNumber}
                                label={t('number-0')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setFilterNumber }
                            />
                        </FormControl>
                    </Grid>


                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchBlocks() }} />
                    </SearchButtonContainer>
                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                    <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                </TitleStyle>
                    <br />
                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br />



            {totalCount == 0 && !loadingData ? <>

            </> : <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
                hideDids={true}
            />}
        </>
    );
}
